import React, { useEffect, useState } from 'react';
import useDebounce from './useDebounce.hook';

/**
 * useRipple hook
 * @param {import('react').RefObject<HTMLElement>} ref
 * @param {Function=} action
 * @param {string=} rippleColor
 * @returns {HTMLElement}
 */
function useRipple(ref, action = () => console.log('useRipples'), rippleColor = '#000000') {
	//ripples are just styles that we attach to span elements
	const [ripples, setRipples] = useState([]);

	useEffect(() => {
		//check if there's a ref
		if (!ref.current) return;

		const elem = ref.current;

		//add a click handler for the ripple
		/**
		 * clickHandler
		 * @param {MouseEvent} e
		 * @returns {void}
		 */
		const clickHandler = (e) => {
			//calculate the position and dimensions of the ripple.
			//based on click position and button dimensions
			const rect = elem.getBoundingClientRect();
			const left = e.clientX - rect.left;
			const top = e.clientY - rect.top;
			const height = elem.clientHeight;
			const width = elem.clientWidth;
			const diameter = Math.max(width, height);
			setRipples((prev) => [
				...prev,
				{
					top: top - diameter / 2,
					left: left - diameter / 2,
					height: Math.max(width, height),
					width: Math.max(width, height),
				},
			]);
		};

		//add an event listener to the button
		elem.addEventListener('click', clickHandler);

		//clean up when the component is unmounted
		return () => {
			elem.removeEventListener('click', clickHandler);
		};
	}, [ref, action]);

	//add a debounce so that if the user doesn't click after 1s, we remove the ripples
	const _debounced = useDebounce(ripples, 600);
	useEffect(() => {
		if (!_debounced.length) return undefined;

		setRipples([]);

		if (action) action();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_debounced]);

	//map through the ripples and return span elements.
	//this will be added to the button component later
	return ripples.map((style, i) => {
		return (
			<span
				key={i}
				style={{
					...style,
					//should be absolutely positioned
					position: 'absolute',
					backgroundColor: rippleColor,
					opacity: 0.25,
					transform: 'scale(0)',
					// add ripple animation from styles.css
					animation: 'ripple 600ms linear',
					borderRadius: '50%',
				}}
			/>
		);
	});
}

export default useRipple;
