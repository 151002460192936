import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoaderGif from '../assets/images/loader.gif';
import { PUBLIC_URL } from '../config';
import Footer from '../components/Footer';
import Header from '../components/Header';

const AboutUs = lazy(() => import('../screens/Aboutus'));
const AccountScreen = lazy(() => import('../screens/Account'));
const AfterLogin = lazy(() => import('../screens/AfterLogin'));
const ContactUs = lazy(() => import('../screens/Contactus'));
const Correction = lazy(() => import('../screens/Correction'));
const ForgotPassword = lazy(() => import('../screens/ForgotPassword'));
const HomeScreen = lazy(() => import('../screens/Home'));
const JudgeDetail = lazy(() => import('../screens/JudgeDetail'));
const LoginScreen = lazy(() => import('../screens/Login'));
const NotFound = lazy(() => import('../screens/NotFound'));
const ProfileScreen = lazy(() => import('../screens/Profile'));
const ResetPassword = lazy(() => import('../screens/ResetPassword'));
const Search = lazy(() => import('../screens/Search'));
const SignupScreen = lazy(() => import('../screens/Signup'));
const Terms = lazy(() => import('../screens/Terms'));
const ReportReview = lazy(() => import('../screens/ReportReview'));

const Fallback = () => (
	<div className="initialLoaderScreen">
		<img src={LoaderGif} alt="" />
	</div>
);

export default function Navigation() {
	const { authorize } = useSelector((state) => state.auth);
	return (
		<Router basename={PUBLIC_URL}>
			<Header />
			<Suspense fallback={<Fallback />}>
				<main>
					<Routes>
						<Route path="/" exact element={authorize ? <AfterLogin /> : <HomeScreen />} />
						<Route path="/login" exact element={<LoginScreen canRedirect />} />
						<Route path="/forgot-password" exact element={<ForgotPassword />} />
						<Route path="/reset-password/:token" exact element={<ResetPassword />} />
						<Route path="/signup" exact element={<SignupScreen />} />
						<Route path="/aboutus" exact element={<AboutUs />} />
						<Route path="/contactus" exact element={<ContactUs />} />
						<Route path="/terms" exact element={<Terms />} />
						<Route path="/search" element={<Search />} />
						{/* <Route path='/search' element={token ? <Search /> : <LoginScreen />} /> */}
						<Route path="/dashboard" element={authorize ? <AfterLogin /> : <LoginScreen />} />
						<Route path="/judgedetail/:id" element={<JudgeDetail />} />
						<Route
							path="/correction/:judgeId"
							element={authorize ? <Correction /> : <LoginScreen />}
						/>
						<Route
							path="/report/:reviewId"
							element={authorize ? <ReportReview /> : <LoginScreen />}
						/>
						<Route path="/profile" element={authorize ? <ProfileScreen /> : <LoginScreen />} />
						<Route path="/account/:tab" element={authorize ? <AccountScreen /> : <LoginScreen />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</main>
			</Suspense>
			<Footer />
		</Router>
	);
}
