import { createSlice } from '@reduxjs/toolkit';

const initialSearch = { state: '', county: '', type: '', searched: '' };

export const userReducer = createSlice({
	name: 'user',
	initialState: {
		data: {},
		lastSearched: initialSearch,
	},
	reducers: {
		addUser: (state, action) => {
			state.data = action.payload;
		},
		onSearch: (state, action) => {
			state.lastSearched.state = action.payload.state;
			state.lastSearched.county = action.payload.county;
			state.lastSearched.type = action.payload.type;
			state.lastSearched.searched = action.payload.searched;
		},
	},
});

export const { addUser, onSearch } = userReducer.actions;

export default userReducer.reducer;
