import { createSlice } from '@reduxjs/toolkit';

export const authReducer = createSlice({
	name: 'auth',
	initialState: { authorize: false },
	reducers: {
		isAuthorize: (state, action) => {
			state.authorize = action.payload;
		},
	},
});

export const { isAuthorize } = authReducer.actions;

export default authReducer.reducer;
