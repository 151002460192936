import { createStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
	user: userReducer,
	auth: authReducer,
});

const store = createStore(reducers);

export default store;
