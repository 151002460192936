import React, { useCallback } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import RippleButton from './RippleButton';

const Footer = () => {
	const navigate = useNavigate();
	const onNavigate = useCallback(() => navigate('/'), [navigate]);
	return (
		<footer>
			<section className="footer-bg">
				<Container>
					<h2 className="footer-lable text-center">
						See what people are saying about your Judges!
					</h2>
					<RippleButton
						title="GET STARTED"
						styleClass="btn btn-premium btn-primary-outline"
						action={onNavigate}
					/>
					<div className="footer-logo">
						<div className="logo-wrapper">
							<img src={Logo} alt="" />
						</div>
						<span className="sep"></span>
					</div>
					<Nav className="footer-nav">
						<LinkContainer to="/">
							<Nav.Link className="nav-link-bg" href="">
								Home
							</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/aboutus">
							<Nav.Link className="nav-link-bg" href="">
								About Us
							</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/contactus">
							<Nav.Link className="nav-link-bg" href="">
								Contact Us
							</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/terms">
							<Nav.Link className="nav-link-bg" href="">
								Terms &amp; Privacy
							</Nav.Link>
						</LinkContainer>
					</Nav>
					<div className="copyright">&copy;2018 JudgeJudger. All Rights Reserved.</div>
				</Container>
			</section>
		</footer>
	);
};

export default Footer;
