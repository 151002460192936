import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import useRipple from '../hooks/useRipple.hook';
import { Button } from 'react-bootstrap';

function RippleButton({
	type,
	title,
	styleClass,
	canNotDelay,
	action,
	variant,
	id,
	disabled,
	rippleColor,
}) {
	const buttonRef = useRef(null);
	/**
	 * pass the ref to the useRipple hook
	 * @type {import('react').RefObject<HTMLElement>}
	 */
	const buttonRipples = useRipple(buttonRef, !canNotDelay && action, rippleColor);
	const styling = useMemo(
		() => styleClass.concat(' position-relative overflow-hidden'),
		[styleClass],
	);
	return (
		<Button
			type={type}
			disabled={disabled}
			id={id}
			ref={buttonRef}
			className={styling}
			variant={variant}
			onClick={canNotDelay && action}
		>
			{buttonRipples}
			{title}
		</Button>
	);
}

RippleButton.propTypes = {
	type: PropTypes.oneOf(['button', 'submit']),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	styleClass: PropTypes.string,
	action: PropTypes.func,
	variant: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	rippleColor: PropTypes.string,
	canNotDelay: PropTypes.bool,
};

RippleButton.defaultProps = {
	disabled: false,
	rippleColor: '#000000',
	type: 'button',
	styleClass: '',
	canNotDelay: false,
};

export default RippleButton;
