import PropTypes from 'prop-types';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { Nav } from 'react-bootstrap';
import useRipple from '../hooks/useRipple.hook';

function RippleLink({ children, action, styleClass }) {
	const linkRef = useRef(null);
	const linkRipples = useRipple(linkRef, action);

	return (
		<Nav.Link
			ref={linkRef}
			className={clsx(`position-relative overflow-hidden`, {
				[styleClass]: !!styleClass,
			})}
		>
			{linkRipples}
			{children}
		</Nav.Link>
	);
}

RippleLink.propTypes = {
	children: PropTypes.element.isRequired,
	styleClass: PropTypes.string,
	action: PropTypes.func.isRequired,
};

RippleLink.defaultProps = {
	action: console.log,
};

export default RippleLink;
