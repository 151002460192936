import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderLogo from '../assets/images/JJ-logo.png';
import Cross from '../assets/images/cross.png';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthorize } from '../redux/authReducer';
import RippleButton from './RippleButton';
import RippleLink from './RippleLink';
import './style.css';

const { innerWidth: width } = window;

function Header() {
	const dispatch = useDispatch();
	const location = useLocation();

	const [user, setUser] = useState({});
	const [headerPadding, setHeaderPadding] = useState('20');
	const [hideTopStrip, setHideTopStrip] = useState(false);
	const { authorize } = useSelector((state) => state.auth);

	const navigate = useNavigate();

	const handleScroll = () => {
		let scrollTop = window.scrollY;
		if (scrollTop < 100) {
			setHeaderPadding(23);
		} else {
			setHeaderPadding(15);
		}
	};

	useEffect(() => {
		const userData = localStorage.getItem('userData');
		setUser(JSON.parse(userData));
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [authorize]);

	useEffect(() => {
		if (location.pathname) window.scrollTo(0, 0);
	}, [location.pathname]);

	const closeBtn = () => {
		setHideTopStrip(true);
	};

	const headerRef = useRef(0);

	useEffect(() => {
		let headerPaddingTop = width > 775 ? 154 : 139;

		if (hideTopStrip) {
			headerPaddingTop = headerPaddingTop - 62;
		}

		headerRef.current.style.paddingTop = `${headerPaddingTop}px`;
	}, [hideTopStrip]);

	function SignOut() {
		localStorage.removeItem('token');
		localStorage.removeItem('userData');
		dispatch(isAuthorize(false));

		navigate('/');
	}
	return (
		<header ref={headerRef}>
			{hideTopStrip ? null : (
				<div id="notification-wrap" className="notification-wrap">
					<span>
						Note: Currently only state Superior Court Judges in California are available for review.
						More states coming soon.
					</span>
					<RippleLink styleClass="header-top-close border-0 py-2" action={closeBtn}>
						<img src={Cross} alt="Cross" />
					</RippleLink>
				</div>
			)}
			<Navbar
				id="navbar"
				bg="light"
				variant="light"
				expand="lg"
				collapseOnSelect
				fixed="top"
				style={{
					top: hideTopStrip ? 0 : 62,
					padding: `${headerPadding}px 0px`,
				}}
			>
				<Container>
					<Navbar.Brand>
						<LinkContainer to="/">
							<Nav.Link className="navbar-logo-title">
								<img src={HeaderLogo} alt="" />
							</Nav.Link>
						</LinkContainer>
					</Navbar.Brand>

					<Navbar.Toggle aria-controls="responsive-navbar-nav" />

					<Navbar.Collapse id="responsive-navbar-nav" className="navbar-collpase-mob">
						<Nav className="ml-auto">
							<LinkContainer to="/">
								<Nav.Link className="navbar-header-menu">Home</Nav.Link>
							</LinkContainer>

							<LinkContainer to="/aboutus">
								<Nav.Link className="navbar-header-menu">About us</Nav.Link>
							</LinkContainer>

							<LinkContainer to="/contactus">
								<Nav.Link className="navbar-header-menu">Contact us</Nav.Link>
							</LinkContainer>

							{user ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Dropdown>
										<Dropdown.Toggle
											variant="primary"
											style={{
												border: 0,
												backgroundColor: 'transparent',
												color: '#c1995f',
											}}
										>
											{user.firstName || 'First Name'}
										</Dropdown.Toggle>

										<Dropdown.Menu id="dropdown-basic-menu">
											<Dropdown.Item onClick={() => navigate('/account/settings')}>
												My Account
											</Dropdown.Item>
											<Dropdown.Item onClick={() => navigate('/profile')}>Profile</Dropdown.Item>
											<Dropdown.Item onClick={() => SignOut()}>Sign Out</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							) : (
								<LinkContainer to="/login" state={{ judgedetail: false }}>
									<RippleButton
										variant="btn-primary"
										styleClass="navbar-header-menu btn btn-primary-grad"
										title="SIGN IN"
										action={() => navigate('/login')}
									/>
								</LinkContainer>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
}

export default Header;
