import React, { useCallback, useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux';
import { isAuthorize } from './redux/authReducer';
import { ToastContainer } from 'react-toastify';
import Navigation from './navigations';
import './App.css';
import packageJson from '../package.json';

import 'react-toastify/dist/ReactToastify.css';

function App() {
	const clearCache = useCallback(async () => {
		const version = localStorage.getItem('version');
		if (version === packageJson.version) return;

		localStorage.clear();
		localStorage.setItem('version', packageJson.version);

		const hasCache = 'caches' in window;
		if (!hasCache) return;

		const keys = await caches.keys();

		await Promise.all(keys.map((key) => caches.delete(key)));

		// Makes sure the page reloads. Changes are only visible after you refresh.
		window.location.reload(true);
	}, []);
	useEffect(() => clearCache(), [clearCache]);

	const authenticates = useCallback(() => {
		const token = localStorage.getItem('token');
		if (token) store.dispatch(isAuthorize(true));
	}, []);
	useEffect(() => authenticates(), [authenticates]);
	return (
		<Provider store={store}>
			<Navigation />
			<ToastContainer position="bottom-right" />
		</Provider>
	);
}

export default App;
